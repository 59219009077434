<script>
import { loadStripe } from '@stripe/stripe-js';
import Layout from "@/views/layouts/table.vue";
import Swal from "sweetalert2";

export default {
    components: {Layout},
    props: {
        clientSecret: {
            type: String,
            required: true
        },
        pK: {
            type: String,
            required: true
        },
        amount: {
            type: [Number, String],
            required: true
        },
        vat: {
            type: [Number, String],
            required: true
        },
        gross: {
            type: [Number, String],
            required: true
        },
        stripeId: {
            type: [Number, String],
            required: true
        }
    },
    data() {
        return {
            stripe: null,
            card: null,
            elements: null,
            loading: false
        };
    },
    async mounted() {
        this.stripe = await loadStripe(this.pK);
        this.elements = this.stripe.elements({clientSecret: this.clientSecret});
        /*
        * {
            hidePostalCode: true,
            classes: 'nrt-card',
            style: {
                base: {
                    backgroundColor: '#ffffff',
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '20px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                }
            }
        }
        * */
        this.card = this.elements.create('payment');
        this.card.mount('#card-element');
    },
    methods: {
        async handleSubmit() {
            /*const { error, paymentIntent } = await this.stripe.confirmPayment(this.clientSecret, {
                payment_method: {
                    card: this.card,
                },
            });*/

            this.loading = true;
            const elements = this.elements;
            const { error, paymentIntent } = await this.stripe.confirmPayment({elements, confirmParams: {}, redirect: 'if_required'});

            if (error) {
                console.error(error.message);
                Swal.fire({
                    title: this.$t('sms.payment_failed'),
                    text: error.message,
                    icon: 'error',
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-danger btn-md me-1',
                    },
                    confirmButtonText: this.$t('table.ok'),
                }).then(() => {
                    this.loading = false;
                })
            } else if (paymentIntent.status === 'succeeded') {
                this.$store.dispatch('invoice/successfulPayment', {
                    id: this.stripeId,
                    amount: this.amount
                }).then(() => {
                    Swal.fire({
                        title: this.$t('sms.successful_payment'),
                        icon: 'success',
                        buttonsStyling: false,
                        customClass: {
                            confirmButton: 'btn btn-success btn-md me-1',
                        },
                        confirmButtonText: this.$t('table.ok'),
                    }).then(() => {
                        this.$router.push({name: 'invoices'});
                    })
                }).finally(() => {
                    this.loading = false;
                });
                console.log('Payment succeeded!');
            }
        }
    },
};
</script>

<template>
    <layout>
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="card-title">{{ $t('invoices.deposit') }}</h4>
                </div>
            </div>
            <div class="card-body bg-white">
                <div class="row justify-content-center border-bottom mb-2 pb-4">
                    <b-col md="6" class="px-3">
                        <div class="flex-column border rounded">
                            <div class="col bg-light pt-2 pb-1 px-3">
                                <h4 class="fw-bold">{{ $t('sms.cost') }}</h4>
                            </div>
                            <div class="col px-3 pt-2 pb-1 border-bottom">
                                <div class="d-flex justify-content-between">
                                    <span>{{ $t('sms.net_price')}}</span>
                                    <strong>{{ $currencyFormatter.format(amount) }}</strong>
                                </div>
                            </div>
                            <div class="col border-bottom px-3 py-1">
                                <div class="d-flex justify-content-between">
                                    <span>{{ $t('sms.vat')}}</span>
                                    <strong>{{ $currencyFormatter.format(vat) }}</strong>
                                </div>
                            </div>
                            <div class="col bg-soft-primary px-3 py-2">
                                <div class="d-flex justify-content-between font-size-18">
                                    <strong>{{ $t('sms.gross_price')}}</strong>
                                    <strong class="text-decoration-underline">{{ $currencyFormatter.format(gross) }}</strong>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </div>
                <form @submit.prevent="handleSubmit">
                    <b-overlay class="p-3" :show="loading">
                        <b-form-row>
                            <b-col md="6" class="m-auto">
                                <div id="card-element" class="border rounded bg-light p-3 mb-4"></div>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col cols="12" class="text-center">
                                <b-button variant="primary" @click="handleSubmit" @keyup.enter="handleSubmit">{{ $t('balance.pay') }}</b-button>
                            </b-col>
                        </b-form-row>
                    </b-overlay>
                </form>
            </div>
        </div>
    </layout>
</template>

<style scoped>

</style>